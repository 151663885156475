<template>
  <div
    role="alert"
    aria-live="polite"
    aria-atomic="true"
    class="alert alert-danger my-space-xs asuo-ie-alert"
  >
    <div class="d-flex align-items-center">
      <font-awesome-icon
        icon="exclamation-triangle"
        size="2x"
        class="text-dark-3"
        aria-label="warning icon"
      ></font-awesome-icon>
      <p class="mb-0 ms-space-sm ms-lg-space-md text-dark-3 fw-bold">
        Internet explorer is not supported. Preferred browsers:
        <NuxtLink
          class="rds-link"
          href="https://www.google.com/chrome/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Chrome
        </NuxtLink>
        and
        <NuxtLink
          class="rds-link"
          href="https://www.mozilla.org/en-US/firefox/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Firefox.
        </NuxtLink>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.asuo-ie-alert {
  display: none;
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .asuo-ie-alert {
    display: block;
  }
}
</style>

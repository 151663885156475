<template>
  <div class="rfi-div bg-dark-3 static-rfi">
    <form
      id="hero-rfi-asuo-static"
      @submit.prevent="handleSubmit"
      data-cy="rfi-form"
    >
      <div
        class="rfi-content text-white pt-space-sm px-space-md pb-space-md pe-lg-space-xl"
      >
        <!-- form fields -->
        <div id="hero-rfi-form-fields">
          <p class="fs-small text-secondary fw-bold mb-space-xxs">
            Step {{ currentStep }} of 2
          </p>
          <h2 class="h2-small mb-space-xxs">Request Information</h2>
          <!-- step 1 -->
          <div v-show="currentStep === 1" id="hero-rfi-step-1-form-fields">
            <p class="fs-xs mb-space-sm">
              To learn more about ASU Online or a specific program, fill out the
              form below and check your email for information on next steps. *
              Indicates a required field
            </p>
            <!-- degree type -->
            <div id="hero-rfi-degree-type-group" class="fw-bold mb-space-sm">
              <label for="hero-rfi-degree-type" class="mb-space-xxs fs-xs"
                >Degree type</label
              >

              <TypeAheadSelect
                id="hero-rfi-degree-type"
                label="text"
                preselect-opacity=".75"
                v-model="(form.degreeType as OptionType)"
                :options="degreeTypeOptions"
                placeholder="Select a degree type"
                @changed="handleDegreeTypeChange"
                data-cy="rfi-degree-type-filter"
                class="fw-normal text-dark-1"
                action-varint="light-5"
              >
              </TypeAheadSelect>
            </div>

            <!-- interest areas -->
            <div
              v-if="!isStudyPage"
              id="hero-rfi-interest-area-group"
              class="fw-bold mb-space-sm"
            >
              <label
                label="Area of interest"
                label-for="hero-rfi-interest-area"
                class="mb-space-xxs fs-xs"
                >Area of interest</label
              >

              <TypeAheadSelect
                id="hero-rfi-interest-area"
                preselect-opacity=".75"
                v-model="(form.interestArea as OptionType)"
                :options="interestAreaOptions"
                placeholder="Select an interest area"
                @changed="handleInterestAreaChange"
                data-cy="rfi-interest-area-filter"
                class="fw-normal text-dark-1"
                action-varint="light-5"
              >
              </TypeAheadSelect>
            </div>

            <!-- programs -->
            <div
              id="hero-rfi-programs-group"
              class="fw-bold position-relative mb-0 pb-space-sm"
            >
              <label
                label="Program(required)"
                label-for="hero-rfi-program"
                class="mb-space-xxs fs-xs"
                >* Program</label
              >

              <TypeAheadSelect
                id="hero-rfi-program"
                label="text"
                preselect-opacity=".75"
                v-model="(form.program as OptionType)"
                :options="programOptions"
                placeholder="Select a program"
                @changed="handleProgramChange"
                data-cy="rfi-program-filter"
                class="fw-normal text-dark-1"
                action-varint="light-5"
              >
              </TypeAheadSelect>
            </div>

            <!-- military checkbox -->
            <div
              id="military-group-hero"
              class="form-check ps-0 position-relative mb-0 pb-space-sm d-flex flex-wrap"
            >
              <label
                class="fw-bold form-check-label fs-xs w-100"
                for="military"
              >
                * Have you served in the U.S. military or are you a military
                dependent?
              </label>
              <div class="form-check" v-for="option in militaryOptions">
                <label class="form-check-label">
                  {{ option.label }}
                  <input
                    class="form-check-input"
                    type="radio"
                    :value="option.value"
                    v-model="form.isMilitary"
                    @click="handleMilitaryClick()"
                  />
                </label>
              </div>
            </div>
          </div>

          <!-- step 2 -->
          <div v-show="currentStep === 2" id="hero-rfi-step-2-form-fields">
            <p class="fs-xs mb-space-sm">* Indicates a required field</p>
            <div class="row">
              <!-- first-name input field -->
              <div
                id="hero-rfi-first-name-group"
                class="col-12 col-lg-6 position-relative mb-0 pb-space-sm d-flex flex-column"
              >
                <label
                  for="hero-rfi-first-name"
                  class="fw-bold mb-space-xxxs fs-xs"
                  >* First name</label
                >
                <input
                  v-model.trim="form.firstName"
                  type="text"
                  id="hero-rfi-first-name"
                  placeholder="Enter your first name"
                  data-cy="rfi-first-name"
                  class="form-control rounded-0"
                  :class="{
                    'is-invalid': firstNameErrorMessage,
                  }"
                  @blur="handleFirstNameBlur()"
                />
                <div v-if="firstNameErrorMessage" class="invalid-feedback">
                  {{ firstNameErrorMessage }}
                </div>
              </div>
              <!-- last-name input field -->
              <div
                id="hero-rfi-last-name-group"
                class="position-relative mb-0 pb-space-sm col-12 col-lg-6 d-flex flex-column"
              >
                <label
                  for="hero-rfi-last-name"
                  class="fw-bold mb-space-xxxs fs-xs"
                  >* Last name</label
                >
                <input
                  v-model.trim="form.lastName"
                  type="text"
                  id="hero-rfi-last-name"
                  placeholder="Enter your last name"
                  data-cy="rfi-last-name"
                  class="form-control rounded-0"
                  :class="{
                    'is-invalid': lastNameErrorMessage,
                  }"
                  @blur="handleLastNameBlur()"
                />
                <div v-if="lastNameErrorMessage" class="invalid-feedback">
                  {{ lastNameErrorMessage }}
                </div>
              </div>
            </div>

            <!-- email input field -->
            <div
              id="hero-rfi-email-group"
              class="position-relative mb-0 pb-space-sm"
            >
              <label for="hero-rfi-email" class="fw-bold mb-space-xxs fs-xs"
                >* Email address</label
              >
              <input
                id="hero-rfi-email"
                type="email"
                placeholder="Enter your email address"
                v-model.trim="form.email"
                @input="handleEmailInput()"
                @blur="handleEmailBlur()"
                class="form-control rounded-0"
                :class="{
                  'is-invalid': emailErrorMessage,
                }"
                data-cy="rfi-email"
              />
              <div v-if="emailErrorMessage" class="invalid-feedback">
                {{ emailErrorMessage }}
              </div>
            </div>

            <!-- phone number input field -->
            <div
              id="hero-rfi-phone-number-group"
              class="position-relative mb-0 pb-space-md"
            >
              <label for="hero-rfi-asuonline" class="fw-bold mb-space-xxs fs-xs"
                >* Phone number</label
              >

              <PhoneInputRfi
                id="hero-rfi-asuonline"
                class="w-100"
                :class="{ 'is-invalid': phoneErrorMessage }"
                :error="phoneErrorMessage != ''"
                v-model.trim="form.phone.number"
                :no-validator-state="true"
                @update="handlePhoneUpdate"
                @blur="handlePhoneBlur"
              >
              </PhoneInputRfi>

              <div
                v-if="phoneErrorMessage"
                class="invalid-feedback mb-space-xxs"
              >
                {{ phoneErrorMessage }}
              </div>
            </div>

            <!-- consent block -->
            <div class="fs-xs mb-space-sm mb-md-space-md gdpr-links">
              <text-gdpr class="link-color" text-class="gdpr"></text-gdpr>
            </div>
          </div>
        </div>
        <!-- submit block -->
        <div class="row gx-0">
          <div class="col-12">
            <div
              class="d-flex align-items-center"
              :class="submitContainerClass"
            >
              <button
                type="button"
                aria-label="go to previous step"
                @click="previousStep"
                v-show="currentStep === 2"
                class="text-white btn p-0"
                data-cy="rfi-previous-step-button"
              >
                <font-awesome-icon
                  icon="arrow-left"
                  size="1x"
                  alt="back icon"
                ></font-awesome-icon>
              </button>
              <button
                v-show="currentStep === 1"
                @click="nextStep"
                data-cy="rfi-continue-button"
                :disabled="!programIsValid"
                type="button"
                class="fw-bold py-space-xxs px-space-sm btn btn-secondary"
              >
                Continue
              </button>
              <button
                v-show="currentStep === 2"
                class="fw-bold py-space-xxs px-space-sm btn btn-secondary"
                type="submit"
                variant="secondary"
                :disabled="submitDisabled"
                data-cy="rfi-submit-button"
              >
                Submit
                <div class="d-inline" v-if="showLoader">
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Loading...</span>
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Loading...</span>
                  <span
                    class="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import TextGdpr from "~/components/rfi/TextGdpr.vue";
import TypeAheadSelect from "../TypeAheadSelect.vue";
import PhoneInputRfi from "./PhoneInputRfi.vue";

import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { DegreeSeekingProgram } from "~/server/resolvers/resolvers-types";

interface OptionType {
  text: string;
  value: string;
}

interface Props {
  isStudyPage?: boolean;
  programList?: DegreeSeekingProgram[];
}

const props = withDefaults(defineProps<Props>(), {
  isStudyPage: false,
});

const {
  form,
  lastNameErrorMessage,
  emailErrorMessage,
  phoneErrorMessage,
  firstNameErrorMessage,
  programIsValid,
  showLoader,
  submitDisabled,
  currentProgramCode,
  currentProgramName,
  degreeTypeOptions,
  programOptions,
  interestAreaOptions,
  handleDegreeTypeChange,
  handleEmailBlur,
  handleEmailInput,
  handleFirstNameBlur,
  handleInterestAreaChange,
  handleLastNameBlur,
  handleMilitaryClick,
  handlePhoneBlur,
  handlePhoneUpdate,
  handleProgramChange,
  handleSubmit,
} = useRfi({
  isStudyPage: props.isStudyPage,
  programList: props.programList,
});

const currentStep = ref(1);
const militaryOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const submitContainerClass = computed(() => {
  return currentStep.value === 1
    ? "justify-content-end"
    : "justify-content-between";
});

const previousStep = () => {
  currentStep.value = currentStep.value - 1;
  analyticsComposable.trackLinkEvent(
    "internal link",
    "main content",
    "request info",
    "back arrow"
  );
};

const nextStep = () => {
  if (currentProgramName.value && currentProgramCode.value) {
    if (props.isStudyPage) {
      analyticsComposable.trackEvent({
        event: "form",
        name: "onclick",
        action: "click",
        type: "click",
        region: "main content",
        section: "request info",
        text: "continue",
        component: "rfi step 1 of 2",
        degree_type:
          form.value.degreeType && form.value.degreeType.value
            ? form.value.degreeType!.value!.toLowerCase()
            : "select a degree type",
        program: currentProgramName.value.toLowerCase(),
        program_code: currentProgramCode.value,
        military_service: form.value.isMilitary == false ? "no" : "yes",
      });
    } else {
      analyticsComposable.trackEvent({
        event: "form",
        name: "onclick",
        action: "click",
        type: "click",
        region: "main content",
        section: "request info",
        text: "continue",
        component: "rfi-hero",
        degree_type:
          form.value.degreeType && form.value.degreeType.value
            ? form.value.degreeType.value.toLowerCase()
            : "select a degree type",
        area_of_interest:
          form.value.interestArea && form.value.interestArea.value
            ? form.value.interestArea.value.toLowerCase()
            : "select an interest area",
        program: currentProgramName.value.toLowerCase(),
        program_code: currentProgramCode.value,
        military_service: form.value.isMilitary == false ? "no" : "yes",
      });
    }
    currentStep.value = currentStep.value + 1;
  } else {
    console.error("missing program name or code, when proceeding to next step");
  }
};
</script>

<style lang="scss" scoped>
:deep() {
  .m-phone-number-input button {
    background-color: transparent;
  }
  .m-phone-number-input {
    --maz-color-bg: var(--rds-dark-3);
    --maz-color-muted: rgba(255, 255, 255, 0.75);
    --maz-color-text: var(--rds-white);
    --maz-border-radius: 0;
  }
  .m-select .m-select-list-item.--is-selected {
    color: var(--rds-dark-3) !important;
    background-color: var(--rds-white) !important;
  }
  .m-select .m-select-list-item {
    color: var(--rds-white) !important;
  }
  .m-select .m-select-list-item:hover {
    color: var(--rds-dark-3) !important;
  }
  .m-input-wrapper {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    --maz-color-primary: var(--rds-secondary);
  }
}

.form-check-input:checked {
  color: var(--rds-dark-3);
  --rds-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  --rds-form-check-bg: var(--rds-secondary);
  background-color: var(--rds-secondary);
  border-color: var(--rds-secondary);
}
.form-check {
  gap: 1rem;
}
// form input css
.form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  padding-top: 0px;
  padding-left: 0px;
  background-color: var(--rds-dark-3, #191919);
  color: #fafafa;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-control:focus {
  color: #ffc627;
  border-color: #ffc627;
  box-shadow: none;
  outline: none;
}

// Typeahead css
:deep(div.typeahead__dropdown-toggle) {
  background-color: var(--rds-dark-3, #191919);
  color: white;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 0;
}
:deep(div.typeahead__action) {
  color: white;
}
:deep(.link-color a) {
  color: #ffc627;
}
.rfi-content {
  width: 434px;
  height: 100%;
}
div.invalid-feedback {
  margin-top: 0 !important;
}
</style>

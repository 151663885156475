<template>
  <div id="asuo-static-layout" :class="layoutClass">
    <header-standard
      v-if="$page?.baseHeader.navItems && $page?.baseHeader.navItems.length > 0"
      :is-sticky="isStickyBrandNav"
      :display-rfi-cta="displayRfiCta"
      :route="useRequestURL()"
      home-title="ASU Online"
      logo-link-url="https://www.asu.edu/"
      logo-link-target="_self"
      :rfi-anchor-id="rfiAnchorId"
      :nav-items="($page?.baseHeader.navItems as NavItem[])"
      main-content-id="content"
      @asuSearchEvent="handleAnalyticsEvent"
      @asuLogoClick="handleAnalyticsEvent"
      @homeClick="handleAnalyticsEvent"
      @homeMobileClick="handleAnalyticsEvent"
      @navLinkClick="handleAnalyticsEvent"
      @navCollapseShown="handleAnalyticsEvent"
      @navCollapseHidden="handleAnalyticsEvent"
      @requestInfoClick="handleAnalyticsEvent"
    >
      <template #cta-block>
        <button-apply-now
          v-if="displayApplyNow"
          class="ms-lg-space-xxxs ms-xxl-space-xs"
          text-size="small"
          :type="applyNowType"
          :link-url="applyNowUrl"
          event-region="navbar"
          event-section="main navbar"
          event-component="header-global"
          :has-size-class="true"
        ></button-apply-now>
      </template>
    </header-standard>

    <main
      id="content"
      role="main"
      region="main content"
      tabindex="-1"
      ref="content"
    >
      <!-- alert-internet-explorer -->
      <div class="asuo-ie-alert">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <alert-internet-explorer></alert-internet-explorer>
            </div>
          </div>
        </div>
      </div>

      <!-- hero rfi section -->
      <section class="p-0 d-flex" :id="heroSectionId">
        <div
          class="container-fluid hero-image p-0 position-relative"
          :style="headerImageStyle"
        >
          <div class="hero-image-gradient w-100 position-absolute"></div>
          <!-- title -->
          <div class="position-absolute shr-title pe-space-xs">
            <h1 class="h1-medium text-white">{{ title }}</h1>
            <p
              class="mb-0 mt-space-sm d-none d-lg-block fs-xl text-secondary fw-bold ab-subtitle"
            >
              {{ subTitle }}
            </p>
          </div>
        </div>

        <!-- rfi -->
        <static-hero-rfi
          :is-study-page="isStudyPage"
          :id="heroRfiId"
          :program-list="programList"
          class="d-none d-lg-block"
        ></static-hero-rfi>
      </section>

      <!-- rfi above content -->
      <static-section-rfi
        v-if="mobileRfiAboveContent"
        :is-study-page="isStudyPage"
        :program-list="programList"
        :bg-color="staticRfiBgColor"
        class="d-block d-lg-none"
        :id="sectionRfiId"
      />
      <!-- a wrapper for slot is needed -->
      <slot />
      <!-- the content -->

      <!-- rfi below content -->
      <static-section-rfi
        v-if="!mobileRfiAboveContent"
        :is-study-page="isStudyPage"
        :program-list="programList"
        :bg-color="staticRfiBgColor"
        class="d-block d-lg-none"
        :id="sectionRfiId"
      />

      <!-- navbar-bottom: visible only on mobile -->
      <navbar-bottom
        class="d-lg-none"
        v-if="displayBottomNav"
        :rfi-anchor-id="rfiAnchorId"
        :display-apply-now="displayApplyNow"
        :apply-now-type="applyNowType"
        :apply-now-url="applyNowUrl"
        :display-rfi-cta="displayRfiCta"
        :display-chat-cta="displayStickyCta"
        :display-contact-button="displayContactButton"
      ></navbar-bottom>
    </main>
    <!-- asuo-footer -->
    <footer-standard
      @tertiaryMenuLinkClick="handleAnalyticsEvent"
      :display-secondary-menu="displayBottomNav"
      :display-apply-now="displayApplyNow"
      :display-rfi-cta="displayRfiCta"
    >
      <template #primary-section> <primary-footer-items /> </template>
      <template #secondary-menu>
        <nav class="nav grid-flex-menu order-1">
          <a
            v-for="(item, index) in secondaryNavItems"
            :key="index"
            :href="item.uri"
            :target="item.target"
            class="nav-link me-0 py-space-xxs py-lg-0 px-0 px-lg-space-xs text-dark-3 menu-item fw-bold"
            rel="noopener noreferrer"
            @click="handleSecondaryMenuLinkClick(item)"
          >
            {{ item.text }}
          </a>
        </nav>
      </template></footer-standard
    >

    <!-- cookie-banner -->
    <cookie-banner></cookie-banner>
  </div>
</template>

<script setup lang="ts">
import {
  analyticsComposable,
  type AnalyticsEventObject,
} from "@rds-vue-ui/analytics-gs-composable";
import { HeaderStandard, type NavItem } from "@rds-vue-ui/header-standard";
import { useWindowSize } from "@vueuse/core";
import AlertInternetExplorer from "~/components/AlertInternetExplorer.vue";
import CookieBanner from "~/components/CookieBanner.vue";
import ButtonApplyNow from "~/components/nav/ButtonApplyNow.vue";
import NavbarBottom from "~/components/nav/NavbarBottom.vue";
import PrimaryFooterItems from "~/components/nav/PrimaryFooterItems.vue";
import StaticHeroRfi from "~/components/rfi/StaticHeroRfi.vue";
import StaticSectionRfi from "~/components/rfi/StaticSectionRfi.vue";
import { ParagraphNavItem } from "~/server/resolvers/resolvers-types";

const { $lozad } = useNuxtApp();

interface BaseHeaderResponse {
  baseHeader: {
    navItems: ParagraphNavItem[];
  };
}

const $page = await usePageQuery<BaseHeaderResponse>(
  `#graphql
    query {
      baseHeader(id: "36c62521-a023-46a6-b15b-b668e0f56758"){
        navItems {
          isActive
          htmlLink {
            text
            uri
            target
          }
          children {
            hasBorderTop
            htmlLink {
              text
              uri
              target
            }
          }
        }
      }
    }`,
  {},
  { key: "header-nav" }
);

interface Category {
  title: string;
}

interface ProgramList {
  category: Category;
  code: string;
  degreeImage: string;
  detailPage: string;
  id: string;
  nextStartDate: string;
  shortDescription: string;
  title: string;
}
interface Props {
  title: string;
  subTitle?: string;
  imageSource: string;
  displayStickyCta?: boolean;
  displayBottomNav?: boolean;
  isStickyBrandNav?: boolean;
  heroSectionId?: string;
  isStudyPage?: boolean;
  staticRfiBgColor?: string;
  isHeaderSticky?: boolean;
  applyNowType?: "modal" | "link";
  applyNowUrl?: string;
  displayApplyNow?: boolean;
  displayRfiCta?: boolean;
  displayContactButton?: boolean;
  mobileRfiAboveContent?: boolean;
  rfiAnchorId?: string;
  heroRfiId?: string;
  sectionRfiId?: string;
  programList?: ProgramList[];
}

const props = withDefaults(defineProps<Props>(), {
  displayStickyCta: true,
  displayBottomNav: true,
  isStickyBrandNav: true,
  heroSectionId: "asuo-static-hero",
  isStudyPage: false,
  staticRfiBgColor: "var(--rds-light-2, #f1f1f1)",
  isHeaderSticky: true,
  applyNowType: "modal",
  applyNowUrl: "#",
  displayApplyNow: true,
  displayRfiCta: true,
  displayContactButton: true,
  mobileRfiAboveContent: false,
  rfiAnchorId: "asuo-rfi-section",
  sectionRfiId: "asuo-rfi-section",
  heroRfiId: "asuo-hero-rfi-section",
});

const { width: windowWidth } = useWindowSize();

const secondaryNavItems = [
  {
    text: "Maps and Locations",
    uri: "https://www.asu.edu/map/interactive/",
    target: "BLNK",
  },
  {
    text: "Jobs",
    uri: "https://www.asu.edu/asujobs",
    target: "BLNK",
  },
  {
    text: "Directory",
    uri: "https://isearch.asu.edu/asu-people/",
    target: "BLNK",
  },
  {
    text: "Contact ASU",
    uri: "https://www.asu.edu/contactasu/",
    target: "BLNK",
  },
  {
    text: "My ASU",
    uri: "https://my.asu.edu/",
    target: "BLNK",
  },
];

const headerImageStyle = computed(() => {
  return { "background-image": `url('${props.imageSource}')` };
});

const layoutClass = () => {
  return props.isStudyPage ? "filtered-program-layout" : "full-rfi-layout";
};

// Salesforce Screensize checks
const isDesktopScreen = computed(() => {
  return windowWidth.value > 991 ? true : false;
});

const rfiAnchorId = computed(() => {
  return isDesktopScreen.value ? props.heroRfiId : props.sectionRfiId;
});

const handleAnalyticsEvent = (evt: AnalyticsEventObject) => {
  analyticsComposable.trackEvent(evt);
};

const handleSecondaryMenuLinkClick = (item: (typeof secondaryNavItems)[0]) => {
  analyticsComposable.trackLinkEvent(
    "internal link",
    "footer",
    "secondary footer",
    item.text.toLowerCase(),
    "footer - innovation"
  );
};

onMounted(() => {
  const observer = $lozad(".rds-lazy");
  observer.observe();
});
</script>

<style lang="scss" scoped>
.asuo-enter-active {
  transition: opacity 0.5s;
}

.asuo-enter {
  opacity: 0;
}

.hero-image {
  min-height: 256px;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.hero-image-gradient {
  height: 100%;
  bottom: 0;
  background: transparent linear-gradient(180deg, #19191900 0%, #191919 100%) 0%
    0% no-repeat padding-box;
  opacity: 1;
}

.filtered-program-layout .hero-image-gradient {
  height: 50%;
  opacity: 0.75;
}

.shr-title {
  bottom: 32px;
  left: 24px;
}

nav.grid-flex-menu {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: column;
  justify-items: start;
  flex-wrap: wrap;
  width: 100%;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .shr-title {
    left: 48px;
    bottom: 32px;
  }
}

/* Large devices (desktops, 992px to 1199px) */
@media (min-width: 992px) and (max-width: 1199px) {
  .shr-title {
    left: calc((100vw - 960px) / 2);
    bottom: 48px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  :deep(.btn-size) {
    padding: 8px 16px;
    font-size: 12px;
  }

  .hero-image {
    min-height: 600px;
  }
  nav.grid-flex-menu {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}

/* X-Large devices (1200px and up) */
@media (min-width: 1200px) {
  .shr-title {
    left: calc((100vw - 1140px) / 2);
    bottom: 48px;
  }
}

/* XX-Large devices (1400px and up) */
@media (min-width: 1400px) {
  .shr-title {
    left: calc((100vw - 1300px) / 2);
  }

  :deep(.btn-size) {
    padding: 8px 16px;
    font-size: 12px;
  }
}
</style>
